import { Button, ButtonProps, Heading, ImageTypes, Paragraph } from '@hexa-ui/components';

import EmptyStateImg from 'assets/empty-state.svg';
import ErrorStateImg from 'assets/error-state.svg';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './StateFeedback.styles';
import { StateFeedbackImage } from './StateFeedbackImage';

export interface IStateFeedbackImageProps {
  width?: number | string;
  height?: number | string;
  src?: string;
  alt?: string;
  variant?: ImageTypes['variant'];
}

interface IStateFeedbackProps {
  title?: string;
  message?: string | ReactElement;
  showButton?: boolean;
  variant: 'error' | 'empty';
  imageProps?: IStateFeedbackImageProps;
  stateFeedbackGap?: string;
  buttonCss?: ButtonProps['css'];
}

const defaultImage = {
  error: ErrorStateImg,
  empty: EmptyStateImg,
};

export const StateFeedback = ({
  message,
  title = '',
  showButton,
  variant,
  imageProps,
  stateFeedbackGap = '$4',
  buttonCss,
}: IStateFeedbackProps) => {
  const hasTitle = !!title;
  const { t } = useTranslation('common');
  const imgProps = {
    ...imageProps,
    src: imageProps?.src || defaultImage[variant],
    alt: imageProps?.alt || `State feedback ${variant} image`,
  };

  return (
    <Styles.Container css={{ gap: stateFeedbackGap }}>
      <StateFeedbackImage {...imgProps} />
      {hasTitle && <Heading size="H2">{title}</Heading>}
      <Paragraph colortype={hasTitle ? 'secondary' : 'primary'} alignment="center">
        {message}
      </Paragraph>
      {showButton && (
        <Button variant="secondary" data-testid="state-feedback-button" css={buttonCss}>
          {t('button.getHelp')}
        </Button>
      )}
    </Styles.Container>
  );
};
