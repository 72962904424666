/* eslint-disable react-hooks/rules-of-hooks */

import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { IChain, IChainGetResponse, IChainPocGetResponse, IPoc } from './ChainManagement.types';

export const API_CHAIN_URL = '/api/link-chain-management/chain';
export const API_CHAIN_POC_GET_URL = `${API_CHAIN_URL}/poc`;

export const useChain = () => {
  const { api } = useApi();
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedVendor, selectedCountry, loadingVendors } = getAppHeaderConfig;

  const get = () => {
    const defaultData = { content: [] };

    const { data = defaultData, isLoading } = useQuery(['chain-get', selectedCountry], async () => {
      const { data = defaultData } = await api.get<IChainGetResponse>({
        url: API_CHAIN_URL,
        config: { headers: { country: selectedCountry } },
      });

      return data;
    });

    return { data: data.content, isLoading };
  };

  const getPocs = (chainName: string) => {
    const defaultData = { content: [] };

    const { data = defaultData, isLoading } = useQuery(
      ['poc-get', chainName, selectedCountry, selectedVendor],
      async () => {
        const { data = defaultData } = await api.get<IChainPocGetResponse>({
          url: `${API_CHAIN_POC_GET_URL}?chainName=${chainName}&vendorId=${selectedVendor}`,
          config: { headers: { country: selectedCountry } },
        });

        return data;
      },
      {
        enabled: !loadingVendors,
      }
    );

    return { data: data.content, isLoading };
  };

  return { get, getPocs };
};

export type { IChain, IPoc };
