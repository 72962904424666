import { IDeliveryDetails } from '@api';
import { DeliveryEnumStatus } from 'enums/lastMile';

export const deliveryDetailsDefault: IDeliveryDetails = {
  id: '',
  distributionCenterId: '',
  accountId: '',
  accountName: '',
  accountAddress: '',
  accountCity: '',
  accountState: '',
  accountZipcode: '',
  driverName: '',
  truckPlate: '',
  estimatedTimeOfArrival: '',
  finishedAt: '',
  orderAmount: 0.0,
  progressBars: [],
  status: {
    value: DeliveryEnumStatus.NOT_STARTED,
    description: '',
    color: 'red',
    timestamp: '',
    reason: '',
  },
  updates: [],
  products: [],
};

export const deliveryDetailsMock: IDeliveryDetails = {
  id: '1',
  distributionCenterId: '123',
  accountId: '00623904000173',
  accountName: 'BeesMart Anália Franco Shopping Mini',
  accountAddress: 'Av. Reg. Feijó, 1739 - Jardim Analia Franco',
  accountCity: 'São Paulo',
  accountState: 'SP',
  accountZipcode: '12227260',
  driverName: 'Lara Braga Jr.',
  orderAmount: 300.2,
  truckPlate: 'ZAE4300',
  estimatedTimeOfArrival: '2023-07-23T19:52:46.000Z',
  finishedAt: '2023-07-24T20:11:41.303Z',
  progressBars: [
    {
      value: 100,
      color: 'green',
    },
    {
      value: 100,
      color: 'green',
    },
    {
      value: 100,
      color: 'green',
    },
  ],
  status: {
    value: DeliveryEnumStatus.CONCLUDED,
    description: 'Delivery completed.',
    color: 'green',
    timestamp: '2023-07-31T12:19:59.783Z',
    reason: '',
  },
  updates: [
    {
      timestamp: '2023-07-31T12:19:59.783Z',
      value: DeliveryEnumStatus.CONCLUDED,
      description: 'Delivery completed.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:18:59.783Z',
      value: DeliveryEnumStatus.DELIVERY_STARTED,
      description: 'Sr. Test is at the store.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:17:59.783Z',
      value: DeliveryEnumStatus.ON_THE_WAY,
      description: 'Sr. Test is on the way.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:16:59.783Z',
      value: DeliveryEnumStatus.NOT_STARTED,
      description: 'Delivery is planned for today.',
      color: 'green',
      reason: '',
    },
  ],
  products: [
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black',
      quantity: 99999,
      price: 250,
      total: 10000,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 10,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage: '',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
  ],
};

export const deliveryDetailsDeliveryTimeReturnedMock: IDeliveryDetails = {
  id: '1',
  distributionCenterId: '123',
  accountId: '00623904000173',
  accountName: 'BeesMart Anália Franco Shopping Mini',
  accountAddress: 'Av. Reg. Feijó, 1739 - Jardim Analia Franco',
  accountCity: 'São Paulo',
  accountState: 'SP',
  accountZipcode: '12227260',
  driverName: 'Lara Braga Jr.',
  truckPlate: 'ZAE4300',
  orderAmount: 300.2,
  estimatedTimeOfArrival: '2023-07-24T19:52:46.000Z',
  finishedAt: '2023-07-24T20:11:41.303Z',
  progressBars: [
    {
      value: 100,
      color: 'red',
    },
    {
      value: 100,
      color: 'red',
    },
    {
      value: 100,
      color: 'red',
    },
  ],
  status: {
    value: DeliveryEnumStatus.DEFINITELY_RETURNED,
    description: 'Delivery declined.',
    color: 'red',
    timestamp: '2023-07-31T12:22:59.783Z',
    reason: 'There are no products available!',
  },
  updates: [
    {
      timestamp: '2023-07-31T12:22:59.783Z',
      value: DeliveryEnumStatus.DEFINITELY_RETURNED,
      description: 'Delivery declined.',
      color: 'red',
      reason: 'There are no products available!',
    },
    {
      timestamp: '2023-07-31T12:21:59.783Z',
      value: DeliveryEnumStatus.IN_TREATMENT,
      description: 'Something went wrong. Finding a solution.',
      color: 'red',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:20:59.783Z',
      value: DeliveryEnumStatus.WAITING_MODULATION,
      description: 'Something went wrong. Finding a solution.',
      color: 'red',
      reason: 'There are no products available',
    },
    {
      timestamp: '2023-07-31T12:19:59.783Z',
      value: DeliveryEnumStatus.DELIVERY_STARTED,
      description: 'Sr. Test is at the store.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:18:59.783Z',
      value: DeliveryEnumStatus.ON_THE_WAY,
      description: 'Sr. Test is on the way.',
      color: 'green',
      reason: '',
    },
    {
      timestamp: '2023-07-31T12:17:59.783Z',
      value: DeliveryEnumStatus.NOT_STARTED,
      description: 'Delivery is planned for today.',
      color: 'green',
      reason: '',
    },
  ],
  products: [
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 99999,
      price: 250,
      total: 10000,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 10,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage: '',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
    {
      sku: '0004293',
      itemName: 'Refrigerante Pepsi Black Pet 200ml Caixa C/ 12 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/04-19-2022-ab348e3f-584b-4750-9632-52bbb37cc200-fc10dd23.jpg',
      quantity: 1,
      price: 13.92,
      total: 13.92,
    },
    {
      sku: '0004293',
      itemName: 'Cerveja Budweiser Zero Lata Sleek 350ml Caixa C/ 8 Un',
      itemImage:
        'https://cms-prod.global.ssl.fastly.net/media/12-06-2022-9ac6af5b-6b93-45ee-bf82-4e3e62ad379b-cc1ce468.png',
      quantity: 1,
      price: 26.6,
      total: 26.6,
    },
  ],
};
