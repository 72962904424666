import { IProgressBar, TProgressBarColor } from '@interfaces';
import * as Styles from './ProgressBars.styles';

type ProgressColor = 'black' | 'green' | 'red' | 'default';

interface Props {
  progressBars: IProgressBar[];
}

export const ProgressBars = ({ progressBars }: Props) => {
  const getProgressColor = (color: TProgressBarColor): ProgressColor => {
    return !color ? 'default' : color;
  };

  return (
    <Styles.CardProgressWrapper className="progress-bar">
      {progressBars.map(({ value, color }, index) => (
        <Styles.ProgressBar
          key={`${value}-${index}`}
          dataTestId="progress-meter"
          maxValue={100}
          value={value}
          color={getProgressColor(color)}
        />
      ))}
    </Styles.CardProgressWrapper>
  );
};
