import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { defaultNS, resources } from 'i18n';
import i18n, { Namespaces } from 'i18next';
import { PropsWithChildren, useEffect } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  supportedLngs: ['en-US', 'pt-BR', 'es-419'],
  ns: [
    'common',
    'accounts',
    'orders',
    'commercialControl',
    'deliveryList',
    'deliveryDetails',
    'ordersPage',
  ] as Array<Namespaces>,
  defaultNS,
  resources,
});

export function I18nProvider(props: PropsWithChildren<object>) {
  const { defaultLanguage, preferredLanguage } = usePreferredLanguageV2();

  useEffect(() => {
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
    } else {
      i18n.changeLanguage(defaultLanguage);
    }
  }, [defaultLanguage, preferredLanguage]);

  return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>;
}
