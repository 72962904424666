import { useAppHeader, useUserMetadata } from 'admin-portal-shared-services';
import { useEffect } from 'react';

export const AppHeader = () => {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { selectedCountry = '' } = appHeaderConfig;
  const { data } = useUserMetadata();

  useEffect(() => {
    let defaultCountry = localStorage.getItem('information-selected-country') || '';

    if (selectedCountry) {
      localStorage.setItem('information-selected-country', selectedCountry);
      defaultCountry = selectedCountry;
    }

    setAppHeaderConfig({
      countryOptions: data?.supportedCountries || [],
      vendorSelect: true,
      countrySelect: true,
      defaultCountry,
    });
  }, [data?.supportedCountries, selectedCountry, setAppHeaderConfig]);

  return null;
};
