import { Tooltip } from '@hexa-ui/components';
import { ReactNode, useEffect, useRef, useState } from 'react';
import * as Styles from './ClampLines.styles';

type TooltipPosition = 'bottom' | 'top' | 'left' | 'right';

type TooltipProps = {
  children?: ReactNode | string;
  position?: TooltipPosition;
};

interface ClampLinesProps {
  text: string;
  lines?: number;
  hasTooltip?: boolean;
  tooltipPosition?: TooltipProps['position'];
}

export const ClampLines = ({
  text,
  lines = 1,
  hasTooltip = true,
  tooltipPosition = 'bottom',
}: ClampLinesProps) => {
  const clampElement = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const isEllipsisActive = () => {
    const { scrollHeight, clientHeight } = clampElement.current ?? {
      scrollHeight: '',
      clientHeight: '',
    };
    return scrollHeight > clientHeight;
  };

  useEffect(() => {
    const handleResize = () => {
      setShowTooltip(isEllipsisActive());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ClampLinesContent = () => (
    <Styles.ClampLinesWrapper
      ref={clampElement}
      style={{ WebkitLineClamp: lines }}
      onMouseEnter={() => isEllipsisActive() && setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {text}
    </Styles.ClampLinesWrapper>
  );

  if (hasTooltip && showTooltip) {
    return (
      <Tooltip placement={tooltipPosition} text={text}>
        {ClampLinesContent()}
      </Tooltip>
    );
  }

  return ClampLinesContent();
};
