import { Image, SkeletonLoader } from '@hexa-ui/components';
import * as Styles from './DeliveryDetails.styles';
import { DeliveryDetailsItem } from './DeliveryDetails.types';
import { CardStatus } from './sub-components/CardStatus/CardStatus';
import { CardText } from './sub-components/CartText/CartText';

interface OrderDatailsProps<T> {
  isLoading?: boolean;
  data?: any[];
}

export const DeliveryDetails = ({ isLoading, data }: OrderDatailsProps<DeliveryDetailsItem>) => {
  if (isLoading) {
    return (
      <Styles.Card
        className="hidden-desktop"
        border="medium"
        elevated="minimal"
        data-testid="delivery-details-loading"
      >
        <Styles.CardImageWrapper style={{ marginTop: '4px !important' }}>
          <SkeletonLoader height="80px" width="80px" />
          <Styles.WrapperSkeleton>
            <SkeletonLoader height="13px" width="100px" />
            <SkeletonLoader height="13px" width="200px" />
            <SkeletonLoader height="13px" width="80px" />
          </Styles.WrapperSkeleton>
        </Styles.CardImageWrapper>
        <SkeletonLoader height="13px" width="200px" />
        <SkeletonLoader height="13px" width="120px" />
        <SkeletonLoader height="13px" width="160px" />
      </Styles.Card>
    );
  }

  if (!data) return null;

  return (
    <>
      {data.map((item, index) => (
        <Styles.Card className="hidden-desktop" key={index} border="medium" elevated="minimal">
          <Styles.CardImageWrapper style={{ marginTop: '4px !important' }}>
            <Image src={item.itemImage} variant="fit" height="80px" width="80px" />
            <CardText
              primaryText={item.sku}
              secondText={item.itemName}
              thirdText={item.package}
              fourthText={item.container}
            />
          </Styles.CardImageWrapper>
          <CardStatus primaryText={item.price} secondText={item.quantity} thirdText={item.total} />
        </Styles.Card>
      ))}
    </>
  );
};
