import { isValid, parseISO } from 'date-fns';

export const getHourAndMinutesFromDate = (value: string) => {
  if (!isValid(new Date(value))) {
    return '';
  }

  const dateUtc = parseISO(value);

  const brTimeZone = 'America/Sao_Paulo';

  const hourAndMinutes = dateUtc.toLocaleTimeString('pt-BR', {
    timeZone: brTimeZone,
    hour: '2-digit',
    minute: '2-digit',
  });

  return hourAndMinutes;
};
