import { CommonProperties, CountryName, ScreenName } from '../types';

export class BaseTracking {
  private screen_name: ScreenName;
  private country: CountryName;

  constructor({ screen_name = 'INFORMATION_CENTER', country }: CommonProperties) {
    this.screen_name = ScreenName[screen_name];
    this.country = CountryName[country];
  }

  getScreenName() {
    return this.screen_name;
  }

  getCountry() {
    return this.country;
  }
}
