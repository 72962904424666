import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  width: '100%',
  padding: '16px',
  gap: '16px',
  borderRadius: '0px !important',
});

export const CardImageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'row',
  gap: '16px',
});

export const WrapperSkeleton = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
