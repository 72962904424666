import { styled } from '@hexa-ui/theme';

export const CardTextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  '& > p': {
    color: '#757575',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '& > :nth-child(2)': {
    color: '#141414',
  },
});
