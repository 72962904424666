import {
  beesCareRequestAccessButtonClick,
  BeesCareRequestAccessButtonClick,
  deliveryDetailsClicked,
  DeliveryDetailsClicked,
  GoToBeesCareButtonClicked,
  goToBeesCareButtonClicked,
  OrderDetailsClicked,
  orderDetailsClicked,
  ticketsTabAccessed,
  TicketsTabAccessed,
} from '@analytics';
import {
  BeesCareRequestAccessButtonClickProps,
  CommonProperties,
  DeliveryDetailsClickedProps,
  GoToBeesCareButtonClickedProps,
  OrderDetailsClickedProp,
  TicketsTabAccessedProps,
  TrackingAttributes,
} from 'tracking/types';
import { BaseTracking } from '..';

export class StoreTracking extends BaseTracking {
  constructor(commonProperties?: CommonProperties) {
    const screen_name = commonProperties?.screen_name ?? 'INFORMATION_CENTER_STORE';
    const country = commonProperties?.country ?? 'BR';
    super({ screen_name, country });
  }

  deliveryDetailsClickedTrack(
    props: TrackingAttributes<DeliveryDetailsClickedProps>
  ): ReturnType<typeof deliveryDetailsClicked> {
    const { data: _data, options, callback } = props;

    const data: DeliveryDetailsClicked = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    deliveryDetailsClicked(data, options, callback);
  }

  ticketsTabAccessedTrack(
    props: TrackingAttributes<TicketsTabAccessedProps>
  ): ReturnType<typeof ticketsTabAccessed> {
    const { data: _data, options, callback } = props;

    const data: TicketsTabAccessed = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    ticketsTabAccessed(data, options, callback);
  }

  beesCareRequestAccessButtonClickTrack(
    props: TrackingAttributes<BeesCareRequestAccessButtonClickProps>
  ): ReturnType<typeof beesCareRequestAccessButtonClick> {
    const { data: _data, options, callback } = props;

    const data: BeesCareRequestAccessButtonClick = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    beesCareRequestAccessButtonClick(data, options, callback);
  }

  goToBeesCareButtonClickedTrack(
    props: TrackingAttributes<GoToBeesCareButtonClickedProps>
  ): ReturnType<typeof goToBeesCareButtonClicked> {
    const { data: _data, options, callback } = props;

    const data: GoToBeesCareButtonClicked = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    goToBeesCareButtonClicked(data, options, callback);
  }

  orderDetailsClickedTrack(
    props: TrackingAttributes<OrderDetailsClickedProp>
  ): ReturnType<typeof orderDetailsClicked> {
    const { data: _data, options, callback } = props;

    const data: OrderDetailsClicked = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    orderDetailsClicked(data, options, callback);
  }
}
