import {
  BeesCareRequestAccessButtonClick,
  ChainSearched,
  ChainSelected,
  DeliveryDetailsClicked,
  DeliveryHistoryClicked,
  GoToBeesCareButtonClicked,
  OrderDetailsClicked,
  StoreSearched,
  StoreSelected,
  TicketsTabAccessed,
} from '@analytics';
import { Callback, Options } from '@segment';

export type CommonProperties = {
  screen_name?: keyof typeof ScreenName;
  country: keyof typeof CountryName;
};

export interface TrackingAttributes<T> {
  data: T;
  options?: Options;
  callback?: Callback;
}

export enum ScreenName {
  INFORMATION_CENTER = 'Information Center',
  INFORMATION_CENTER_CHAIN = 'Information Center Chain',
  INFORMATION_CENTER_STORE = 'Information Center Store',
  DELIVERY_DETAILS = 'Delivery details',
}

export enum CountryName {
  BR = 'BRAZIL',
  ZA = 'SOUTH_AFRICA',
  US = 'UNITED_STATES',
}

/**  InformationTracking Types */
export type InformationCenterViewedProps = Omit<TrackingAttributes<unknown>, 'data'>;

/**  ChainTracking Types */
export type ChainSearchedProps = Omit<ChainSearched, 'screen_name' | 'country'>;
export type ChainSelectedProp = Pick<ChainSelected, 'chain_name'>;
export type OrderDetailsClickedProp = Omit<OrderDetailsClicked, 'screen_name' | 'country'>;
export type StoreSearchedProps = Omit<StoreSearched, 'screen_name' | 'country'>;
export type StoreSelectedProps = Omit<StoreSelected, 'screen_name' | 'country'>;

/**  DeliveryTracking Types */
export type DeliveryHistoryClickedProps = Omit<DeliveryHistoryClicked, 'screen_name' | 'country'>;

/**  StoreTracking Types */
export type DeliveryDetailsClickedProps = Omit<DeliveryDetailsClicked, 'screen_name' | 'country'>;
export type TicketsTabAccessedProps = Omit<TicketsTabAccessed, 'screen_name' | 'country'>;
export type BeesCareRequestAccessButtonClickProps = Omit<
  BeesCareRequestAccessButtonClick,
  'screen_name' | 'country'
>;
export type GoToBeesCareButtonClickedProps = Omit<
  GoToBeesCareButtonClicked,
  'screen_name' | 'country'
>;
