import { Paragraph } from '@hexa-ui/components';
import { Stack } from '@mui/material';
import { DisplayInfoProps } from './DisplayInfo.types';

export const DisplayInfo = ({ primaryInfo, secondaryInfo }: DisplayInfoProps) => {
  return (
    <Stack>
      {primaryInfo && (
        <Paragraph size="small" weight="semibold">
          {primaryInfo}
        </Paragraph>
      )}
      {typeof secondaryInfo === 'string' ? (
        <Paragraph size="small">{secondaryInfo}</Paragraph>
      ) : (
        secondaryInfo
      )}
    </Stack>
  );
};
