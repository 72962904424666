import { Image } from '@hexa-ui/components';
import { IStateFeedbackImageProps } from '../StateFeedback';
import * as Styles from './StateFeedbackImage.styles';

export const StateFeedbackImage = (props: IStateFeedbackImageProps) => {
  const {
    width = 116,
    height = 132,
    src = '',
    alt = 'State feedback image',
    variant = 'fit',
  } = props;
  return (
    <Styles.Container>
      <Image width={width} height={height} variant={variant} src={src} alt={alt} />
    </Styles.Container>
  );
};
