import { styled } from '@hexa-ui/theme';
import { Stack } from '@mui/material';
export const Container = styled(Stack, {
  alignItems: 'center',
});
export const ColorOrderStatusHeader = styled('span', {
  borderRadius: '100%',
  marginRight: '$2',
  opacity: '$opacityOpaque',
  variants: {
    type: {
      pending: {
        color: '$neutral100',
      },
      modified: {
        color: '$neutral100',
      },
      placed: {
        color: '$neutral100',
      },
      confirmed: {
        color: '$neutral100',
      },
      invoiced: {
        color: '$neutral100',
      },
      in_transit: {
        color: '$neutral100',
      },
      delivered: {
        color: '$neutral100',
      },
      cancelled: {
        color: '$neutral100',
      },
      denied: {
        color: '$neutral100',
      },
      available: {
        color: '$neutral100',
      },
    },
  },
});
