import { StatusIcon } from '@components';
import { Paragraph } from '@hexa-ui/components';
import { getHourAndMinutesFromDate } from '@utils';
import * as Styles from './ProgressBarExpandableTextStatus.styles';

interface IProgressBarExpandableTextStatusProps {
  color: string;
  text: string;
  hour: string;
  animation?: boolean;
  showLine?: boolean;
  showHour?: boolean;
}

export const ProgressBarExpandableTextStatus = ({
  color,
  text,
  hour,
  animation = false,
  showLine = true,
  showHour = true,
}: IProgressBarExpandableTextStatusProps) => {
  const isSuccess = color === 'green';
  const hourAndTime = getHourAndMinutesFromDate(hour);

  return (
    <Styles.Container showLine={showLine} className={showHour ? 'showHour' : ''}>
      <StatusIcon success={isSuccess} error={!isSuccess} animation={animation} />
      {showHour && (
        <Paragraph size="small" colortype="disabled">
          {hourAndTime}
        </Paragraph>
      )}
      <Paragraph size="small" colortype={animation ? 'primary' : 'disabled'}>
        {text}
      </Paragraph>
    </Styles.Container>
  );
};
