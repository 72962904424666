import { IDelivery } from '@api';
import { ProgressBars } from '@components';
import { getFormattedCNPJ, getTruckPlateFormatted } from '@utils';
import { useTranslation } from 'react-i18next';
import * as Styles from './LastMileCard.styles';
import { CardLoading, CardStatus, CardText } from './sub-components';

interface Props {
  onClick?: (data: IDelivery, event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  showStore?: boolean;
}

interface PropsWithCardData extends Props {
  cardData: IDelivery;
  isLoading?: boolean;
}

interface PropsWithLoading extends Props {
  isLoading: boolean;
  cardData?: IDelivery;
}

export const LastMileCard = ({
  cardData,
  isLoading,
  showStore,
  onClick,
}: PropsWithCardData | PropsWithLoading) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <CardLoading showStore={showStore} />;
  }

  if (!cardData) return null;

  const { truckPlate, accountName, accountId, progressBars, status } = cardData;

  const formattedTruckPlate = getTruckPlateFormatted(truckPlate);
  const formatedAccountId = getFormattedCNPJ(accountId);
  const isCardClickable = typeof onClick === 'function';

  return (
    <Styles.Card
      border="medium"
      elevated="small"
      clickable={isCardClickable}
      onClick={(e) => onClick?.(cardData, e)}
    >
      <CardText primaryText={`${t('lastMile.truckPlate')}:`} secondText={formattedTruckPlate} />
      {showStore && (
        <>
          <CardText
            primaryText={`${t('lastMile.store')}:`}
            secondText={accountName}
            truncate2Lines
          />
          <CardText secondText={formatedAccountId} secondTextWeight="normal" />
        </>
      )}
      <ProgressBars progressBars={progressBars} />
      <CardStatus color={status.color} text={status.description} />
    </Styles.Card>
  );
};
