import { Pagination as PaginationComponent } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './Pagination.styles';

interface IPaginationProps {
  pageData?: unknown[];
  isShowPagination?: boolean;
  callBack: (data) => void;
  customPageSize?: number;
  customPageSizeOptions?: number[];
}

export const Pagination = ({
  pageData = [],
  isShowPagination = true,
  callBack,
  customPageSize,
  customPageSizeOptions = [5, 10, 20, 50],
}: IPaginationProps) => {
  const { t } = useTranslation(['common']);

  const [pageSize, setPageSize] = useState(customPageSize ?? 10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    callBack(pageData.slice(start, end));
  }, [currentPage, pageData, pageSize, callBack]);

  const handleOnChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  if (!isShowPagination) return null;

  return (
    <Styles.PaginationWrapper css={{ $$justifyContent: 'end' }}>
      <PaginationComponent
        total={pageData.length}
        pageSize={pageSize}
        current={currentPage}
        pageSizeOptions={customPageSizeOptions}
        showPageSizeSelector
        onChange={handleOnChange}
        showQuantityIndicator={true}
        pageSizeOptionsIntl={(option) => `${t('common:table.rowsPerPage')}: ${option}`}
        quantityIndicatorIntl={t('common:table.paginationCounter')}
      />
    </Styles.PaginationWrapper>
  );
};
