import { pageViewed } from '@analytics';
import { ValueStream } from 'enums/segment';

interface PageViewedEvent {
  screen_name: string;
}

export const pageViewedTracking = ({ screen_name }: PageViewedEvent) => {
  const data = {
    referrer: null,
    screen_name,
    url: window.location.href,
    store_id: null,
    value_stream: ValueStream.LINK_POST_ORDER,
  };
  pageViewed(data);
};
