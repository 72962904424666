/* eslint-disable react-hooks/rules-of-hooks */
import { ICountryDictionary } from '@interfaces';
import { useAppHeader, usePreferredLanguageV2 } from 'admin-portal-shared-services';

const currencies: ICountryDictionary = {
  AR: '$',
  BE: '€',
  BO: 'Bs.',
  BR: 'R$',
  CA: '$',
  CL: '$',
  CO: '$',
  DE: '€',
  DO: 'RD$',
  EC: '$',
  ES: '€',
  GB: '£',
  HN: 'L',
  KR: '₩',
  MX: '$',
  NL: '€',
  PA: 'B/.',
  PE: 'S/',
  PH: '₱',
  PY: 'Gs.',
  SV: '$',
  TZ: 'TSh',
  UG: 'UGX',
  US: '$',
  UY: '$',
  ZA: 'R',
  ID: 'Rp',
  TH: '฿',
  GH: 'GH₵',
};

export const getFormatCurrency = () => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  const [appHeaderConfig] = useAppHeader();
  const { defaultCountry } = appHeaderConfig;

  const formatCurrency = (value: number): string => {
    const effectiveLocale = preferredLanguage || defaultLanguage;

    const currencyInfo = defaultCountry ? currencies[defaultCountry] : '$';

    const options: Intl.NumberFormatOptions = {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    };

    const formatter = new Intl.NumberFormat(effectiveLocale, options);
    let formattedValue = formatter.format(value);

    formattedValue = formattedValue.replace(/\s/g, '');
    formattedValue = `${currencyInfo} ${formattedValue}`;
    return formattedValue;
  };

  return { formatCurrency };
};
