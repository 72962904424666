import enUS from './languages/en-US.json';
import ptBR from './languages/pt-BR.json';
import esAR from './languages/es-AR.json';

export type Language = 'en-US' | 'pt-BR' | 'es-419';

export const defaultNS = 'common';

export const resources = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': esAR,
} as const;
