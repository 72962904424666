import React from 'react';
import { Paragraph } from '@hexa-ui/components';
import { DisplayState } from './sub-components/DisplayState';
import * as Styles from './DisplayFeedback.styles';

interface IDisplayFeedback {
  type: 'error' | 'empty';
  title?: string;
  description?: string;
  requestTraceID?: string;
  children?: React.ReactNode;
}
export const DisplayFeedback = ({
  type,
  title,
  description,
  requestTraceID,
  children,
}: IDisplayFeedback) => {
  const hasButton = type === 'error';

  return (
    <Styles.Card border="medium" elevated="small">
      <Styles.DisplayFeedbackWrapper>
        <DisplayState type={type} title={title} description={description} hasButton={hasButton} />
        {requestTraceID && (
          <Paragraph alignment="center" size="xsmall" className="marginTop">
            {'RequestTraceID: ' + requestTraceID}
          </Paragraph>
        )}
        {children}
      </Styles.DisplayFeedbackWrapper>
    </Styles.Card>
  );
};
