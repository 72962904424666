import * as Styles from './StatusIcon.styles'

interface Props {
  success: boolean
  error: boolean
  animation?: boolean
}

export const StatusIcon = ({ success, error, animation = true }: Props) => {
  return (
    <Styles.Icon
      success={success}
      error={error}
      animation={animation}
      data-testid={`status-icon-with-animation-${animation}`}
    />
  )
}
