import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  width: '100%',
  height: '100%',
  padding: '23px 24px',
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
    },
  },
  '& .progress-bar': {
    marginTop: '$2',
  },
});
