import { SkeletonLoader } from '@hexa-ui/components';
import * as Styles from './SectionLoading.styles';

interface SectionLoadingProps {
  width: string;
  descriptionLines: number;
}

export const SectionLoading = ({ width, descriptionLines }: SectionLoadingProps) => {
  return (
    <Styles.ComponentWrapper>
      <SkeletonLoader
        data-testid="header-skeleton-loader"
        height="16px"
        width={descriptionLines === 0 ? width : '65px'}
      />
      {descriptionLines > 0 &&
        Array.from({ length: descriptionLines }).map((_, index) => (
          <SkeletonLoader
            key={index}
            data-testid="description-skeleton-loader"
            height="16px"
            width={width}
          />
        ))}
    </Styles.ComponentWrapper>
  );
};
