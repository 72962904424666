import { SkeletonLoader } from '@hexa-ui/components';
import * as Styles from './CardLoading.styles';

interface Props {
  showStore?: boolean;
}

const renderSkeletonLoaderRow = () => (
  <Styles.LoaderWrapper>
    <SkeletonLoader height="16px" />
    <Styles.Spacing />
    <SkeletonLoader height="16px" />
  </Styles.LoaderWrapper>
);

export const CardLoading = ({ showStore }: Props) => {
  return (
    <Styles.Card border="medium" elevated="small" data-testid="card-loading">
      {renderSkeletonLoaderRow()}

      {showStore && <SkeletonLoader height="16px" />}

      <Styles.LoaderRow showStore={showStore}>
        <SkeletonLoader height="4px" />
        <SkeletonLoader height="4px" />
        <SkeletonLoader height="4px" />
      </Styles.LoaderRow>

      <SkeletonLoader height="16px" />
    </Styles.Card>
  );
};
