import { Paragraph as HexaParagraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '8px 1fr',
  gap: '$2',

  '&.showHour': {
    gridTemplateColumns: '8px 36px 1fr',
  },
  '& > div': {
    marginRight: '$1',
    marginTop: '6px',
    '&::after': {
      content: ' ',
      background: '$neutral20',
      position: 'absolute',
      width: '2px',
      height: 'calc(100% + 10px);',
      display: 'none',
      top: '14px',
      left: '3px',
    },
  },
  variants: {
    showLine: {
      true: {
        '& > div': {
          '&::after': {
            display: 'block',
          },
        },
      },
    },
  },
});

export const Paragraph = styled(HexaParagraph, {
  lineHeight: '20px',
  color: '#141414',
});

export const ParagraphHours = styled(HexaParagraph, {
  lineHeight: '20px',
  fontWeight: '600 !important',
  color: '#999999',
});
