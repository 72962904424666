import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  alignItems: 'center',
  padding: '24px 0',
});

export const DisplayFeedbackWrapper = styled('div', {
  padding: '40px 0',
  '.marginTop': {
    marginTop: '16px',
  },
  '& > p': {
    color: '#757575',
    lineHeight: '18px',
    maxWidth: '330px',
  },
});
