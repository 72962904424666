export const getFormattedZipCode = (text: string) => {
  const cep = text.replace(/\D/g, '');
  const CEP_REGEX = /^(\d{5})(\d{3})$/;

  if (CEP_REGEX.test(cep)) {
    return cep.replace(CEP_REGEX, '$1-$2');
  }

  return '';
};
