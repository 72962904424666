import { IProgressBar } from '@interfaces';
import { IDeliveryTimeStatus } from 'api/LastMile';
import { DeliveryEnumStatus } from 'enums/lastMile';

export interface IPostDeliveryDetailsResponse {
  id: string;
  vendorId: string;
  distributionCenter: {
    id: string;
    externalId: string;
    displayId: string;
    name: string;
    tradeName: string;
    timezone: string;
  };
  account: {
    accountId: string;
    customerAccountId: string;
    displayName: string;
  };
  accountDeliveryAddress: {
    address: string;
    city: string;
    state: string;
    zipcode?: string | null;
    latitude: number;
    longitude: number;
  };
  chain?: {
    id: string;
    name: string;
  };
  estimatedTimeOfArrival: string;
  driverName: string;
  truckPlate: string;
  total: number;
  progressBars: IProgressBar[];
  updates: IDeliveryTimeStatus[];
  items: {
    sku: string;
    itemName: string;
    itemImage: string;
    quantity: number;
    price: number;
    total: number;
    itemPlatformId: string;
    container: {
      name: string;
      unitOfMeasurement: string;
      returnable: boolean;
      itemSize?: number;
    };
    packageItem: {
      name: string;
      itemCount: number;
    };
  }[];
  serviceStatus: IDeliveryTimeStatus;
}

export const deliveryDetailsDefault: IPostDeliveryDetailsResponse = {
  id: '',
  vendorId: '',
  distributionCenter: {
    id: '',
    externalId: '',
    displayId: '',
    name: '',
    tradeName: '',
    timezone: '',
  },
  account: {
    accountId: '',
    customerAccountId: '',
    displayName: '',
  },
  accountDeliveryAddress: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
    latitude: 0,
    longitude: 0,
  },
  chain: {
    id: '',
    name: '',
  },
  estimatedTimeOfArrival: '',
  driverName: '',
  truckPlate: '',
  total: 0,
  progressBars: [
    {
      value: 0,
      color: '',
    },
  ],
  updates: [
    {
      value: DeliveryEnumStatus.NOT_STARTED,
      description: '',
      color: 'green',
      timestamp: '',
      reason: '',
    },
  ],
  items: [
    {
      sku: '',
      itemName: '',
      itemImage: '',
      quantity: 0,
      price: 0,
      total: 0,
      itemPlatformId: '',
      container: {
        name: '',
        unitOfMeasurement: '',
        returnable: false,
        itemSize: 0,
      },
      packageItem: {
        name: '',
        itemCount: 0,
      },
    },
  ],
  serviceStatus: {
    value: DeliveryEnumStatus.DEFINITELY_RETURNED,
    description: '',
    color: 'red',
    timestamp: '',
    reason: '',
  },
};
