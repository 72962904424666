import { deliveryHistoryClicked, DeliveryHistoryClicked, deliverySkUsViewed } from '@analytics';
import { CommonProperties, DeliveryHistoryClickedProps, TrackingAttributes } from 'tracking/types';
import { BaseTracking } from '..';

export class DeliveryTracking extends BaseTracking {
  constructor(commonProperties?: CommonProperties) {
    const screen_name = commonProperties?.screen_name ?? 'DELIVERY_DETAILS';
    const country = commonProperties?.country ?? 'BR';
    super({ screen_name, country });
  }

  deliveryHistoryClickedTrack(
    props: TrackingAttributes<DeliveryHistoryClickedProps>
  ): ReturnType<typeof deliveryHistoryClicked> {
    const { data: _data, options, callback } = props;

    const data: DeliveryHistoryClicked = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    deliveryHistoryClicked(data, options, callback);
  }

  deliverySkUsViewedTrack(
    props: TrackingAttributes<DeliveryHistoryClickedProps>
  ): ReturnType<typeof deliverySkUsViewed> {
    const { data: _data, options, callback } = props;

    const data: DeliveryHistoryClicked = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    deliverySkUsViewed(data, options, callback);
  }
}
