import { IProduct } from '@api';
import { Image, Paragraph } from '@hexa-ui/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledContainer } from './DisplayProduct.styles';

export const DisplayProduct = ({
  image,
  description,
  sku,
  ean,
  package: pkg,
  container,
}: IProduct) => {
  const { t } = useTranslation('products');
  const { itemSize, unitOfMeasurement } = container;
  const { itemCount, unitCount } = pkg;
  const unitText = itemCount > '1' ? t('unit.other') : t('unit.one');
  const containerInfo = !!(itemSize && unitOfMeasurement);
  const packageInfo = !!(itemCount && unitCount);
  const showPkgInfos = !!Object.values(pkg).filter((item) => item).length && packageInfo;
  const showContainerInfos =
    !!Object.values(container).filter((item) => item).length && containerInfo;

  return (
    <Stack direction="row" alignItems="center">
      <Image
        src={image}
        variant="fit"
        height="55px"
        width="55px"
        containerCss={{ padding: '$1 0 $1 0' }}
      />
      <StyledContainer>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Paragraph size="small" css={{ marginLeft: '$4', color: '$neutral50' }}>
            {sku}
          </Paragraph>
          <Paragraph size="small" css={{ marginLeft: '$2', color: '$neutral50' }}>
            {ean}
          </Paragraph>
        </div>
        <Paragraph size="small" css={{ marginLeft: '$4', marginBottom: '$4' }}>
          {description}
        </Paragraph>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '16px',
          }}
        >
          {showPkgInfos && (
            <Paragraph size="small" css={{ color: '$neutral50' }}>
              {unitCount}x{itemCount} {unitText}
            </Paragraph>
          )}
          {showPkgInfos && showContainerInfos && <span style={{ margin: '0 5px' }}>•</span>}
          {showContainerInfos && (
            <Paragraph size="small" css={{ color: '$neutral50' }}>
              {itemSize} {unitOfMeasurement}
            </Paragraph>
          )}
        </div>
      </StyledContainer>
    </Stack>
  );
};
