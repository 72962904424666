import { IDelivery, useLastMile } from '@api';
import { LastMileCard } from '@components';
import { Heading, Paragraph } from '@hexa-ui/components';
import { useValidateCountry } from '@hooks';
import { useTracking } from '@tracking';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Carousel } from '../Carousel';
import * as Styles from './LastMileCarousel.styles';

interface Props {
  accountId: string;
  showStore?: boolean;
}

export const LastMileCarousel = ({ accountId, showStore }: Props) => {
  const { t } = useTranslation();
  const { storeTracking } = useTracking();
  const { data, isLoading, isError } = useLastMile().getDeliveries(accountId);
  const navigate = useNavigate();
  const location = useLocation();
  const isBrCountry = useValidateCountry();

  if (!isBrCountry) return null;

  const handleClickLastMileCard = (data: IDelivery) => {
    const { id, distributionCenterId } = data;
    const trackData = { button_label: 'Card details' };

    storeTracking?.deliveryDetailsClickedTrack({ data: trackData });

    navigate(`${location.pathname}/${distributionCenterId}/${id}`);
  };

  const errorMessage = (
    <>
      {t('lastMile.unableToLoad')}
      <br />
      {t('lastMile.contactSupport')}
    </>
  );

  return (
    <Styles.Container>
      <Styles.TitleContainer>
        <Heading size="H3">
          {`${t('lastMile.outForDelivery')}`}
          {!isError ? ` (${data.length})` : ''}
        </Heading>
        <Paragraph weight="semibold" colortype="secondary">
          {isError || data.length ? t('lastMile.estimatedToArriveToday') : ''}
        </Paragraph>
      </Styles.TitleContainer>
      <Carousel
        isLoading={isLoading}
        loadingSlide={<LastMileCard isLoading />}
        isError={isError}
        errorMessage={errorMessage}
      >
        {data.map((item) => (
          <LastMileCard
            key={`${item.accountId}-${item.estimatedTimeOfArrival}-${item.distributionCenterId}`}
            cardData={item}
            onClick={handleClickLastMileCard}
            showStore={showStore}
          />
        ))}
      </Carousel>
    </Styles.Container>
  );
};
