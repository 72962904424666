import { Badge } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const BadgeWrapper = styled(Badge.Status, {
  justifyContent: 'center',
  width: 'fit-content',
  '& .c-jzrCjA': {
    fontSize: '12px',
  },
});
