import { buttonClicked } from '@analytics';
import { ValueStream } from 'enums/segment';

interface ButtonClickedEvent {
  button_label: string;
  button_name: string;
  screen_name: string;
}

export const buttonClickedTracking = ({
  button_label,
  button_name,
  screen_name,
}: ButtonClickedEvent) => {
  const data = {
    button_label,
    button_name,
    referrer: null,
    screen_name,
    url: window.location.href,
    store_id: null,
    value_stream: ValueStream.LINK_POST_ORDER,
  };
  buttonClicked(data);
};
