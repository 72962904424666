import { styled } from '@hexa-ui/theme';

export const ComponentWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  '& img': {
    marginBottom: '16px',
  },
  '& p': {
    width: '100%',
    fontSize: '$2',
    lineHeight: '20px',
    paddingBottom: '16px',
    color: 'rgba(20, 20, 20, 0.56)',
  },
  '& h2': {
    width: '100%',
    padding: '0 0 4px',
    fontSize: '$6',
    lineHeight: '32px',
    color: '#141414',
  },
});
