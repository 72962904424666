import { Card as HexaCard, IconButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const paginationHeight = '$8';

export const Card = styled(HexaCard, {
  width: '100%',
  height: '100%',
  padding: '$6',
  alignItems: 'center',
});

export const Container = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 $6',

  '--swiper-pagination-bullet-size': '7px',
  '--swiper-pagination-bullet-horizontal-gap': '1px',
  '& .swiper': {
    padding: `$2 $2 ${paginationHeight}`,
    width: '100%',
  },
  '& .swiper-pagination-lock': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .swiper-pagination-bullet:only-child': {
    display: 'flex !important',
  },
  '& .swiper-slide': {
    height: 'auto !important',
  },
  variants: {
    loading: {
      true: {
        '& .swiper-pagination': {
          cursor: 'default',
          pointerEvents: 'none',
        },
        '& .swiper-pagination-bullet': {
          cursor: 'default',
          pointerEvents: 'none',
          background: '#c8c8c8',
          opacity: 1,
        },
      },
    },
  },
});

export const Button = styled(IconButton, {
  variants: {
    isError: {
      true: {
        transition: 'all 0s',
        marginTop: 0,
      },
      false: {
        marginTop: `-${paginationHeight}`,
      },
    },
  },
});
