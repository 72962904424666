import { useEnvironment } from '@hooks';
import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import { IGet, IPost } from './api.types';

export const useApi = () => {
  const { baseUrl: baseURL } = useEnvironment();
  const auth = useAuthenticationService();
  const token = auth.getAuthHeader();
  const requestTraceId = `information-center-${v4()}`;

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: token,
      requestTraceId: requestTraceId,
    },
  });

  const api = {
    get: function get<T>(props: IGet): Promise<AxiosResponse<T>> {
      return axiosInstance.get<T>(props.url, props.config);
    },
    post: function post<T, D = unknown>(props: IPost<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.post<T>(props.url, props.data, props.config);
    },
  };

  return { api, requestTraceId };
};
