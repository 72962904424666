import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  width: '100%',
  heigth: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$4',
});
