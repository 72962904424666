import { styled } from '@hexa-ui/theme';

export const SectionWrapper = styled('div', {
  fontSize: '$3',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  '& h5, p': {
    color: '#141414',
  },
  '& > h5': {
    lineHeight: '$5',
  },
  '@media (min-width: 601px)': {
    padding: '0',
  },
});
