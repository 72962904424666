import { StateFeedback } from '@components';
import { ArrowLeft, ArrowRight } from '@hexa-ui/icons';
import { t } from 'i18next';
import { ReactElement, useState } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import * as Styles from './Carousel.styles';

interface Props {
  children: ReactElement[];
  isLoading?: boolean;
  loadingSlide?: ReactElement;
  isError?: boolean;
  errorMessage?: string | ReactElement;
  className?: string;
  disableArrow?: boolean;
  breakpointsValues?: number[];
}

export const Carousel = ({
  children,
  isLoading,
  loadingSlide,
  isError,
  errorMessage,
  className,
  disableArrow = false,
  breakpointsValues = [0, 820, 1220, 1620, 2020, 2420, 2820, 3320],
}: Props) => {
  const [mountedOnlyForJest, setMountedOnlyForJest] = useState(false);
  const [swiperControl, setSwiperControl] = useState<SwiperClass>();
  const [swiper, setSwiper] = useState<SwiperClass>();

  const handleClickPrevButton = () => swiperControl?.slidePrev();

  const handleClickNextButton = () => swiperControl?.slideNext();

  const handlePaginationUpdate = (swiper: SwiperClass) => {
    setSwiper((prevSwiper) => ({ ...prevSwiper, ...swiper }));
  };

  const handleSwiper = (swiper: SwiperClass) => {
    if (mountedOnlyForJest) return;

    setSwiper(swiper);
    setSwiperControl(swiper);

    setMountedOnlyForJest(true);
  };

  const breakpoints = breakpointsValues.reduce((acc, breakpoint, index) => {
    acc[breakpoint] = {
      slidesPerView: index + 1,
      slidesPerGroup: index + 1,
    };
    return acc;
  }, {});

  const loadingSlides = Array.from(
    { length: breakpoints[swiperControl?.currentBreakpoint]?.slidesPerView },
    (_, key) => <SwiperSlide key={key}>{loadingSlide}</SwiperSlide>
  );
  const hasNoDeliveries = !children?.length;
  const renderContent = () => {
    if (hasNoDeliveries)
      return (
        <Styles.Card border="medium" elevated="small" css={{ margin: '$4 0' }}>
          <StateFeedback message={t('lastMile.emptyDeliveries')} variant="empty" />
        </Styles.Card>
      );
    return children.map((item) => <SwiperSlide key={item.key}>{item}</SwiperSlide>);
  };

  return (
    <Styles.Container loading={isLoading} className={className}>
      {!disableArrow && (
        <Styles.Button
          aria-label="previous button"
          variant="secondary"
          icon={ArrowLeft}
          onClick={handleClickPrevButton}
          disabled={swiper?.isBeginning ?? isError}
          isError={isError}
        />
      )}
      {isError ? (
        <Styles.Card border="medium" elevated="small" css={{ margin: '$2' }}>
          <StateFeedback message={errorMessage} variant="error" showButton />
        </Styles.Card>
      ) : (
        <Swiper
          spaceBetween={16}
          modules={[Pagination]}
          pagination={
            !isError &&
            !hasNoDeliveries && { clickable: true, dynamicBullets: true, dynamicMainBullets: 3 }
          }
          onSwiper={handleSwiper}
          onPaginationUpdate={handlePaginationUpdate}
          breakpoints={breakpoints}
          centerInsufficientSlides
        >
          {isLoading ? loadingSlides : renderContent()}
        </Swiper>
      )}
      {!disableArrow && (
        <Styles.Button
          aria-label="next button"
          variant="secondary"
          icon={ArrowRight}
          onClick={handleClickNextButton}
          disabled={swiper?.isEnd ?? swiper?.isLocked ?? isError}
          isError={isError}
        />
      )}
    </Styles.Container>
  );
};
