import { useAppHeader } from 'admin-portal-shared-services';

export const useValidateCountry = (country = 'BR') => {
  const [appHeaderConfig] = useAppHeader();

  const isSelectedCountry =
    appHeaderConfig.selectedCountry?.toUpperCase() === country.toUpperCase();

  return isSelectedCountry;
};
