import * as Styles from './Badge.styles';

interface IBadgeProps {
  label: string;
  type?: 'default' | 'interactive';
  color?: 'blue' | 'info' | 'purple' | 'gray' | 'yellow' | 'green' | 'orange' | 'red';
}

export const Badge = ({ label, type = 'default', color }: IBadgeProps) => {
  return <Styles.BadgeWrapper type={type} color={color} label={label} />;
};
