import { DeliveryEnumStatus } from 'enums/lastMile';

export const isCompletedDelivery = (value: DeliveryEnumStatus) => {
  return value === DeliveryEnumStatus.CONCLUDED || value === DeliveryEnumStatus.PARTIAL_DELIVERY;
};

export const isDefinitelyReturned = (value: DeliveryEnumStatus) => {
  return value === DeliveryEnumStatus.DEFINITELY_RETURNED;
};

export const isNotStarted = (value: DeliveryEnumStatus) => {
  return value === DeliveryEnumStatus.NOT_STARTED;
};
