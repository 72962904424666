import React from 'react';
import * as Styles from './Section.styles';
import { Heading, Paragraph } from '@hexa-ui/components';

interface SectionProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
}

export const Section = ({ title, description, children }: SectionProps) => {
  return (
    <Styles.SectionWrapper>
      <Heading size="H5">{title}</Heading>
      {description && <Paragraph>{description}</Paragraph>}
      {children}
    </Styles.SectionWrapper>
  );
};
