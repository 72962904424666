import { StatusIcon } from '@components';
import * as Styles from './CardStatus.styles';

interface Props {
  color: string;
  text: string;
}

export const CardStatus = ({ color, text }: Props) => {
  const isSuccess = color === 'green';

  return (
    <Styles.CardStatusWrapper>
      <StatusIcon success={isSuccess} error={!isSuccess} />
      <Styles.Paragraph>{text}</Styles.Paragraph>
    </Styles.CardStatusWrapper>
  );
};
