import { ProgressMeter } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const CardProgressWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$2',
});

export const ProgressBar = styled(ProgressMeter, {
  '& > progress::-webkit-progress-value': {
    background: 'transparent!important',
  },
  variants: {
    color: {
      halfBlack: {
        '& > progress::-webkit-progress-value': {
          background: '#141414!important',
        },
      },
      black: {
        '& > progress::-webkit-progress-value': {
          background: '#141414!important',
        },
      },
      green: {
        '& > progress::-webkit-progress-value': {
          background: '$semanticSuccessBasis !important',
        },
      },
      red: {
        '& > progress::-webkit-progress-value': {
          background: '$semanticErrorBasis !important',
        },
      },
      default: {
        '& > progress::-webkit-progress-value': {
          background: 'transparent!important',
        },
      },
    },
  },
});
