/* eslint-disable react-hooks/rules-of-hooks */

import { useValidateCountry } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { deliveryDetailsDefault } from 'mocks/deliveryDetailsMock';
import {
  IAccountDetails,
  IDelivery,
  IDeliveryDetails,
  IDeliveryTime,
  IDeliveryTimeStatus,
  IGetDeliveriesResponse,
} from './LastMile.types';

export const API_LAST_MILE_URL = '/api/link-last-mile-service';
export const API_DELIVERIES_URL = `${API_LAST_MILE_URL}/deliveries`;
export const API_DELIVERIES_DETAILS_URL = `${API_LAST_MILE_URL}/deliveries-details`;

export const useLastMile = () => {
  const { api } = useApi();
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedCountry } = getAppHeaderConfig;
  const isBrCountry = useValidateCountry();

  const getDeliveries = (accountId?: string) => {
    const defaultData: IGetDeliveriesResponse = {
      items: [],
    };

    const {
      data = defaultData,
      isLoading,
      isError,
    } = useQuery<IGetDeliveriesResponse>(
      ['deliveries', accountId, selectedCountry],
      async () => {
        const { data = defaultData } = await api.get<IGetDeliveriesResponse>({
          url: `${API_DELIVERIES_URL}?accountId=${accountId}&page=0&pageSize=1000`,
          config: {
            headers: { country: selectedCountry },
          },
        });

        return data;
      },
      { enabled: isBrCountry && !!accountId, cacheTime: 0 }
    );

    return { data: data.items, isLoading, isError };
  };

  const getDeliveryDetails = (id?: string, distributionCenterId?: string, accountId?: string) => {
    const {
      data = deliveryDetailsDefault,
      isLoading,
      isError,
    } = useQuery<IDeliveryDetails>(
      ['deliveries-details', id, distributionCenterId, accountId, selectedCountry],
      async () => {
        const { data = deliveryDetailsDefault } = await api.get<IDeliveryDetails>({
          url: `${API_DELIVERIES_DETAILS_URL}?id=${id}&distributionCenterId=${distributionCenterId}&accountId=${accountId}`,
          config: { headers: { country: selectedCountry } },
        });
        return data;
      },
      {
        cacheTime: 0,
      }
    );

    return { data, isLoading, isError };
  };

  return { getDeliveries, getDeliveryDetails };
};

export type { IAccountDetails, IDelivery, IDeliveryDetails, IDeliveryTime, IDeliveryTimeStatus };
