import { styled } from '@hexa-ui/theme';

export const ClampLinesWrapper = styled('p', {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  maxWidth: '100%',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  margin: '0',
  lineHeight: '120%',

  '> p': {
    color: '#060606',
    fontFamily: 'Work Sans !important',
    fontWeight: '400 !important',
    margin: '0',
  },
});
