export enum DeliveryEnumStatus {
  NOT_STARTED = 'NOT_STARTED',
  ON_THE_WAY = 'ON_THE_WAY',
  DELIVERY_STARTED = 'DELIVERY_STARTED',
  CONCLUDED = 'CONCLUDED',
  WAITING_MODULATION = 'WAITING_MODULATION',
  IN_TREATMENT = 'IN_TREATMENT',
  PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
  DEFINITELY_RETURNED = 'DEFINITELY_RETURNED',
  RESCHEDULED = 'RESCHEDULED',
}
