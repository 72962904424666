import {
  OrderDetailsClicked,
  StoreSearched,
  StoreSelected,
  orderDetailsClicked,
  storeSearched,
  storeSelected,
} from '@analytics';
import {
  CommonProperties,
  OrderDetailsClickedProp,
  StoreSearchedProps,
  StoreSelectedProps,
  TrackingAttributes,
} from 'tracking/types';
import { BaseTracking } from '..';

export class ChainTracking extends BaseTracking {
  constructor(commonProperties?: CommonProperties) {
    const screen_name = commonProperties?.screen_name ?? 'INFORMATION_CENTER_CHAIN';
    const country = commonProperties?.country ?? 'BR';
    super({ screen_name, country });
  }

  orderDetailsClickedTrack(
    props: TrackingAttributes<OrderDetailsClickedProp>
  ): ReturnType<typeof orderDetailsClicked> {
    const { data: _data, options, callback } = props;

    const data: OrderDetailsClicked = {
      screen_name: this.getScreenName(),
      button_label: _data.button_label,
      country: this.getCountry(),
    };

    orderDetailsClicked(data, options, callback);
  }

  storeSearchedTrack(
    props: TrackingAttributes<StoreSearchedProps>
  ): ReturnType<typeof storeSearched> {
    const { data: _data, options, callback } = props;

    const data: StoreSearched = {
      screen_name: this.getScreenName(),
      quantity_of_registers: _data.quantity_of_registers,
      search_text: _data.search_text,
      country: this.getCountry(),
    };

    storeSearched(data, options, callback);
  }

  storeSelectedTrack(
    props: TrackingAttributes<StoreSelectedProps>
  ): ReturnType<typeof storeSelected> {
    const { data: _data, options, callback } = props;

    const data: StoreSelected = {
      screen_name: this.getScreenName(),
      store_name: _data.store_name,
      country: this.getCountry(),
    };

    storeSelected(data, options, callback);
  }
}
