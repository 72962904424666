import {
  ChainSearched,
  ChainSelected,
  InformationCenterViewed,
  chainSearched,
  chainSelected,
  informationCenterViewed,
} from '@analytics';
import {
  ChainSearchedProps,
  ChainSelectedProp,
  CommonProperties,
  InformationCenterViewedProps,
  TrackingAttributes,
} from 'tracking/types';
import { BaseTracking } from '..';

export class InformationCenterTracking extends BaseTracking {
  constructor(commonProperties?: CommonProperties) {
    const screen_name = commonProperties?.screen_name ?? 'INFORMATION_CENTER';
    const country = commonProperties?.country ?? 'BR';
    super({ screen_name, country });
  }

  informationCenterViewedTrack(
    props: InformationCenterViewedProps = {}
  ): ReturnType<typeof informationCenterViewed> {
    const { options, callback } = props;

    const data: InformationCenterViewed = {
      screen_name: this.getScreenName(),
      country: this.getCountry(),
    };

    informationCenterViewed(data, options, callback);
  }

  chainSearchedTrack(
    props: TrackingAttributes<ChainSearchedProps>
  ): ReturnType<typeof chainSearched> {
    const { data: _data, options, callback } = props;

    const data: ChainSearched = {
      screen_name: this.getScreenName(),
      quantity_of_registers: _data.quantity_of_registers,
      search_text: _data.search_text,
      country: this.getCountry(),
    };

    chainSearched(data, options, callback);
  }

  chainSelectedTrack(
    props: TrackingAttributes<ChainSelectedProp>
  ): ReturnType<typeof chainSelected> {
    const { data: _data, options, callback } = props;

    const data: ChainSelected = {
      chain_id: '',
      chain_name: _data.chain_name,
      contracts: [],
      country: this.getCountry(),
      screen_name: this.getScreenName(),
    };

    chainSelected(data, options, callback);
  }
}
