export function formatCurrency(value: number, locale = 'en-ZA', currency = 'ZAR'): string {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
  };

  if (currency !== 'JPY') {
    options.minimumFractionDigits = 2;
  }

  const formatter = new Intl.NumberFormat(locale, options);

  return formatter.format(value);
}
