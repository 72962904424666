import { Paragraph } from '@hexa-ui/components';
import { useTranslation } from 'react-i18next';
import * as Styles from './CardStatus.styles';

export interface CardTextProps {
  primaryText?: string;
  secondText?: number;
  thirdText?: string;
}

export const CardStatus = ({ primaryText, secondText, thirdText }: CardTextProps) => {
  const { t } = useTranslation('deliveryDetails');

  const primaryTitle = t('cardStatus.unitPrice');
  const secondaryTitle = t('cardStatus.quantity');
  const thirdTitle = t('cardStatus.totalPrice');

  return (
    <Styles.CardWrapper>
      <Styles.RowContainer>
        <Paragraph>{primaryTitle}</Paragraph>
        <Paragraph>{primaryText}</Paragraph>
      </Styles.RowContainer>
      <Styles.RowContainer>
        <Paragraph>{secondaryTitle}</Paragraph>
        <Paragraph>{secondText}</Paragraph>
      </Styles.RowContainer>
      <Styles.RowContainer>
        <Paragraph>{thirdTitle}</Paragraph>
        <Paragraph>{thirdText}</Paragraph>
      </Styles.RowContainer>
    </Styles.CardWrapper>
  );
};
