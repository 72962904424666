import { Grid } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import { getAppHeaderConfig } from 'constants/getAppHeaderConfig';
import { useDynamicSidebar } from 'hooks/useDynamicSidebar';
import { useEffect } from 'react';
import * as Styles from './PageWrapper.styles';
import { PageWrapperProps } from './PageWrapper.types';
import { HeaderGlobalStyle } from 'styles/Global/GlobalStyles';

export const PageWrapper = ({ children, pageName, ...props }: PageWrapperProps) => {
  const [, setAppHeaderConfig] = useAppHeader();

  useDynamicSidebar();

  useEffect(() => {
    const setBreadcrumb = async () => {
      const appHeaderConfig = await getAppHeaderConfig(pageName);

      setAppHeaderConfig(appHeaderConfig);
    };

    /* istanbul ignore next */
    setBreadcrumb().catch((error) => console.error(error));
  }, [pageName, setAppHeaderConfig]);

  return (
    <Grid.Container sidebar type="fluid" {...props}>
      <Styles.Content>{children}</Styles.Content>
      <HeaderGlobalStyle />
    </Grid.Container>
  );
};
