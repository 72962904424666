import * as Styles from './CardText.styles';

export type FontWeight = 'normal' | 'medium' | 'semibold' | undefined;
export type ColorType = 'primary' | 'secondary' | 'disabled' | undefined;

export interface CardTextProps {
  primaryText?: string;
  secondText?: string;
  colorPrimaryText?: ColorType;
  colorSecondText?: ColorType;
  primaryWeight?: FontWeight;
  secondTextWeight?: FontWeight;
  truncate2Lines?: boolean;
}

export const CardText = ({
  primaryText = '',
  secondText = '',
  colorPrimaryText = 'primary',
  colorSecondText = 'primary',
  primaryWeight = 'medium',
  secondTextWeight = 'semibold',
  truncate2Lines = false,
}: CardTextProps) => {
  return (
    <Styles.CardTextWrapper>
      <Styles.Paragraph weight={primaryWeight} colortype={colorPrimaryText}>
        {primaryText}
      </Styles.Paragraph>
      <Styles.Paragraph
        weight={secondTextWeight}
        truncate2Lines={truncate2Lines}
        colortype={colorSecondText}
      >
        {secondText}
      </Styles.Paragraph>
    </Styles.CardTextWrapper>
  );
};
