import { useAppHeader } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import {
  ChainTracking,
  DeliveryTracking,
  InformationCenterTracking,
  StoreTracking,
} from 'tracking';
import { CountryName } from 'tracking/types';

interface ITrackingInstances {
  chainTracking?: ChainTracking;
  deliveryTracking?: DeliveryTracking;
  informationCenterTracking?: InformationCenterTracking;
  storeTracking?: StoreTracking;
}

export const useTracking = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const country = selectedCountry?.toUpperCase() as keyof typeof CountryName;
  const [trackingInstances, setTrackingInstances] = useState<ITrackingInstances>({});

  useEffect(() => {
    if (!country) return;

    const chainTracking = new ChainTracking({ country });
    const deliveryTracking = new DeliveryTracking({ country });
    const informationCenterTracking = new InformationCenterTracking({ country });
    const storeTracking = new StoreTracking({ country });

    setTrackingInstances({
      chainTracking,
      deliveryTracking,
      informationCenterTracking,
      storeTracking,
    });
  }, [country]);

  return trackingInstances;
};
