import { keyframes, styled } from '@hexa-ui/theme'

const iconSuccessAnimation = keyframes({
  '0%': {
    boxShadow: '0 0 0 0 rgba(49, 190, 31, 0.4)',
  },
  '70%': {
    boxShadow: '0 0 0 8px rgba(49, 190, 31, 0)',
  },
  '100%': {
    boxShadow: '0 0 0 0 rgba(49, 190, 31, 0)',
  },
})

const iconErrorAnimation = keyframes({
  '0%': {
    boxShadow: '0 0 0 0 rgba(229, 60, 50, 0.4)',
  },
  '70%': {
    boxShadow: '0 0 0 8px rgba(229, 60, 50, 0)',
  },
  '100%': {
    boxShadow: '0 0 0 0 rgba(229, 60, 50, 0)',
  },
})

export const Icon = styled('div', {
  minWidth: '8px',
  height: '8px',
  borderRadius: '50%!important',
  background: '#f49d16',
  boxShadow: '0 0 0 rgb(49, 190, 31)',
  zIndex: '1',
  variants: {
    animation: {
      true: {},
    },
    success: {
      true: {
        background: '$semanticSuccessBasis',
      },
    },
    error: {
      true: {
        background: '$semanticErrorBasis',
      },
    },
  },
  compoundVariants: [
    {
      success: 'true',
      animation: 'true',
      css: {
        animation: `${iconSuccessAnimation} 2s infinite`,
      },
    },
    {
      error: 'true',
      animation: 'true',
      css: {
        animation: `${iconErrorAnimation} 2s infinite`,
      },
    },
  ],
})
