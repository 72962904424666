import { ExpandableSection } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Expandable = styled(ExpandableSection, {
  position: 'relative',

  '& button': {
    width: '2rem',
    height: '2rem',
    zIndex: 1,
    position: 'absolute',
    top: '-5px',
    right: 0,
    '& svg': {
      color: '#757575',
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  '& > div': {
    boxShadow: 'none',
    padding: '6px 0px 10px 6px',
    margin: '-6px 0px -10px -6px',
    borderRadius: '$none',
    minWidth: '100%',
  },
  '& > div > header': {
    paddingRight: '32px',
  },
  '&.expanded > div > header': {
    marginBottom: '$4',
  },
  '& > div > div': {
    marginTop: '-36px',
  },
  '& > div > div > div': {
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& > :first-child': {
      paddingRight: '32px',
    },
  },
  variants: {
    hasUpdates: {
      false: {
        '& button': {
          visibility: 'hidden',
        },
      },
      true: {},
    },
  },
});
