/* eslint-disable react-hooks/rules-of-hooks */

import { useQuery } from '@tanstack/react-query';
import { useAppHeader, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { getFormattedDate } from './getFormattedDate/getFormattedDate';
import {
  IGetChains,
  IGetOrderDetailsResponse,
  IGetOrders,
  IGetOrdersResponse,
  IGetStores,
  IOrderDetails,
  orderChainDefault,
  orderDetailsDefault,
  orderStoresDefault,
} from './useGetOrders.types';

const API_ORDER_TRACKING_URL = 'api/link-order-tracking/v3';
const API_ORDER_DETAILS_URL = 'api/link-order-tracking/v4/details';
const API_ORDER_CHAINS = 'api/link-customer-list-service/account?user_id=';
const API_ORDER_STORES = 'api/link-customer-list-service/account/v2?userId=';

interface IGetCheckableListFilter {
  userId: string;
  selectedVendor: string;
}

export const useGetOrders = () => {
  const { api, requestTraceId } = useApi();
  const [getAppHeaderConfig] = useAppHeader();

  const { selectedCountry, defaultCountry } = getAppHeaderConfig;
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();

  const language = preferredLanguage ?? defaultLanguage;
  const country = selectedCountry ?? defaultCountry;

  const postOdersList = ({
    accountIds,
    orderStatus,
    orderNumber,
    poNumber,
    orderRegions,
    restriction,
    page,
    pageSize,
    includeStatus,
    deliveryStartDate,
    deliveryEndDate,
    startDate,
    endDate,
    needsAttention,
    inTransit,
    vendorIds,
    vendorOrderNumber,
  }: IGetOrders) => {
    const _deliveryStartDate = deliveryStartDate && getFormattedDate(deliveryStartDate);
    const _deliveryEndDate = deliveryEndDate && getFormattedDate(deliveryEndDate);
    const _startDate = startDate && getFormattedDate(startDate);
    const _endDate = endDate && getFormattedDate(endDate);

    const {
      data: response,
      isLoading,
      isError,
      error,
      isFetching,
      refetch,
    } = useQuery<IGetOrdersResponse>(
      [
        'orders',
        accountIds,
        orderStatus,
        orderNumber,
        poNumber,
        orderRegions,
        restriction,
        page,
        pageSize,
        includeStatus,
        deliveryStartDate,
        deliveryEndDate,
        startDate,
        endDate,
        needsAttention,
        inTransit,
        vendorIds,
        vendorOrderNumber,
      ],
      async () => {
        const response = await api.post<IGetOrdersResponse>({
          url: API_ORDER_TRACKING_URL,
          data: {
            accountId: accountIds,
            orderStatus,
            orderNumber,
            poNumber,
            vendorOrderNumber,
            orderRegions,
            restriction,
            page,
            pageSize,
            includeStatus,
            deliveryStartDate: _deliveryStartDate,
            deliveryEndDate: _deliveryEndDate,
            startDate: _startDate,
            endDate: _endDate,
            needsAttention,
            inTransit,
            vendorIds,
          },
          config: {
            headers: {
              country: country,
            },
          },
        });
        return { ...response.data, requestTraceId };
      },
      { cacheTime: 0 }
    );

    const data: IGetOrdersResponse = {
      content: response?.content ?? [],
      pagination: response?.pagination ?? {
        page: 0,
        pageSize: 0,
        totalPages: 0,
        totalElements: 0,
      },
      totals: response?.totals ?? {
        total: 0,
        totalInProgress: 0,
        totalNeedAttention: 0,
        totalCancelled: 0,
        totalCompleted: 0,
        percentageInProgress: 0,
        percentageNeedAttention: 0,
        percentageCancelled: 0,
        percentageCompleted: 0,
      },
    };

    return {
      data,
      isLoading,
      isError,
      error,
      isFetching,
      refetch,
      requestTraceID: requestTraceId,
    };
  };

  const getOrderDetails = ({
    accountId,
    orderNumber,
    poNumber,
    vendorId,
    page,
    pageSize,
  }: IOrderDetails) => {
    const dataObject = {
      accountId,
      orderNumber,
      vendorId,
      page,
      pageSize,
      poNumber,
    };

    const {
      data = orderDetailsDefault,
      isLoading,
      isError,
      refetch,
    } = useQuery<IGetOrderDetailsResponse>(
      ['order-details', selectedCountry, dataObject],
      async () => {
        const response = await api.get<IGetOrderDetailsResponse>({
          url: `${API_ORDER_DETAILS_URL}`,
          config: {
            headers: { country: selectedCountry, 'Accept-Language': `${language}` },
            params: dataObject,
          },
        });
        return { ...response.data, requestTraceId };
      },
      {
        cacheTime: 0,
      }
    );

    return { data, isLoading, isError, requestTraceId, refetch };
  };

  const getChains = ({ userId, selectedVendor }: IGetCheckableListFilter) => {
    const {
      data = orderChainDefault,
      isLoading,
      isError,
      refetch,
    } = useQuery<IGetChains[]>(
      ['order-chains', selectedCountry, userId, selectedVendor],
      async () => {
        const response = await api.get<IGetChains[]>({
          url: `${API_ORDER_CHAINS}${userId}&vendorId=${selectedVendor}`,
          config: {
            headers: { country: selectedCountry, 'Accept-Language': `${language}` },
          },
        });
        return response.data;
      },
      {
        cacheTime: 0,
      }
    );

    return { data, isLoading, isError, requestTraceId, refetch };
  };

  const getStores = ({ userId, selectedVendor }: IGetCheckableListFilter) => {
    const {
      data = orderStoresDefault,
      isLoading,
      isError,
      refetch,
    } = useQuery<IGetStores[]>(
      ['order-stores', selectedCountry, userId, selectedVendor],
      async () => {
        const response = await api.get<IGetStores[]>({
          url: `${API_ORDER_STORES}${userId}&vendorId=${selectedVendor}`,
          config: {
            headers: { country: selectedCountry, 'Accept-Language': `${language}` },
          },
        });
        return response.data;
      },
      {
        cacheTime: 0,
      }
    );

    return { data, isLoading, isError, requestTraceId, refetch };
  };

  return { postOdersList, getOrderDetails, getChains, getStores };
};
