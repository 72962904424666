import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginBottom: '$4',
});

export const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});
