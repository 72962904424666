import { Paragraph as HexaParagraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const CardStatusWrapper = styled('div', {
  '& > div': {
    display: 'inline-block',
    marginRight: '$2',
  },
});

export const Paragraph = styled(HexaParagraph, {
  fontSize: '$2',
  lineHeight: '$5',
  display: 'inline',
});
