import { useQuery } from '@tanstack/react-query';
import { useAppHeader, usePermissionService } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { TSidebarResponse } from './Sidebar.types';

export const useSidebarService = () => {
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedCountry } = getAppHeaderConfig;
  const { api } = useApi();
  const permissionService = usePermissionService();

  const { data = [], isSuccess } = useQuery(['sidebar', selectedCountry], async () => {
    const { data = { content: [] } } = await api.get<TSidebarResponse>({
      url: 'api/modern-trade-management/mfe',
      config: {
        headers: {
          requestTraceId: 'sidebar',
          country: selectedCountry,
        },
      },
    });
    /* istanbul ignore next */
    return data.content.filter((res) =>
      res.id == 'common'
        ? permissionService.hasScopesToApp(`${res.permission}`)
        : permissionService.hasOneOfScopes(`${res.permission}`)
    );
  });

  return { data, isSuccess };
};
