import { useSegmentAnalytics } from '@hooks';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { EnvConfig, GlobalProvider } from 'providers';
import { Router } from './routes';

export default function App(props: EnvConfig) {
  useSegmentAnalytics(props.SEGMENT_KEY);

  return (
    <GlobalProvider env={props}>
      <AppHeader />
      <Router />
    </GlobalProvider>
  );
}
