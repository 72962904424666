import { Paragraph } from '@hexa-ui/components';
import { ClampLines } from 'components/ClampLines/ClampLines';
import * as Styles from './CardText.styles';

export interface CardTextProps {
  primaryText?: string;
  secondText?: string;
  thirdText?: {
    name: string;
    itemCount: number;
  };
  fourthText?: {
    name?: string;
    itemSize?: number;
    unitOfMeasurement?: string;
    returnable?: boolean;
  };
}

export const CardText = ({
  primaryText = '',
  secondText = '',
  thirdText = {
    name: '',
    itemCount: 0,
  },
  fourthText = {
    name: '',
    itemSize: 0,
    unitOfMeasurement: 'teste',
    returnable: false,
  },
}: CardTextProps) => {
  const formattedThirdText = `${thirdText.itemCount} ${thirdText.name}`;
  const formattedFourthText =
    fourthText.itemSize && `• ${fourthText.itemSize}${fourthText.unitOfMeasurement?.toLowerCase()}`;

  return (
    <Styles.CardTextWrapper>
      <Paragraph>{primaryText}</Paragraph>
      <Paragraph>{ClampLines({ text: secondText })}</Paragraph>
      <Paragraph>
        {formattedThirdText} {formattedFourthText}
      </Paragraph>
    </Styles.CardTextWrapper>
  );
};
