/* eslint-disable react-hooks/rules-of-hooks */

import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { format, subMonths } from 'date-fns';
import {
  IOrder,
  IOrderAccount,
  IOrderTrackingGetTrackingsResponse,
  IOrderTrackingPost,
  IOrderTrackingPostManagerResponse,
  IOrderTrackingPostResponse,
  IProduct,
} from './OrderTracking.types';
export const API_ORDER_TRACKING_URL = '/api/v1/link-order-tracking';

const twoMonthsAgo = (parseDate) => format(subMonths(parseDate, 2), 'yyyy-MM-dd');

const defaultPostManagerData = {
  orderStatus: ['CONFIRMED', 'MODIFIED', 'IN_TRANSIT', 'CANCELLED', 'DELIVERED'],
  orderNumber: null,
  poNumber: null,
  startDate: twoMonthsAgo(new Date()),
  endDate: format(new Date(), 'yyyy-MM-dd'),
  restriction: false,
  orderRegions: ['CENTRAL', 'CAPE', 'EAST_COAST', 'INLAND', 'OTHER_REGIONS'],
};

export const useOrderTracking = () => {
  const { api } = useApi();
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedCountry } = getAppHeaderConfig;

  const post = ({ chainName, accountId, page, pageSize }: IOrderTrackingPost) => {
    const defaultData: IOrderTrackingPostResponse = {
      content: [],
      pagination: {
        totalElements: 0,
        page: 0,
        pageSize: 0,
        totalPages: 0,
      },
    };

    const { data = defaultData } = useQuery(
      ['order-tracking-post', chainName, `${accountId}`, page, pageSize],
      async () => {
        const { data = defaultData } = await api.post<IOrderTrackingPostResponse>({
          url: API_ORDER_TRACKING_URL,
          data: {
            ...defaultData,
            accountId,
            page,
            pageSize,
          },
          config: { headers: { country: selectedCountry } },
        });

        return data;
      },
      { enabled: !!accountId.length }
    );

    return {
      data: data.content,
      pagination: data.pagination,
    };
  };

  const postManager = (chainName: string, accountId: string[]) => {
    const defaultData: IOrderTrackingPostManagerResponse = {
      totalOpen: 0,
      totalCancelled: 0,
      total: 0,
      summaryStatus: {
        CANCELLED: 0,
        CONFIRMED: 0,
        DELIVERED: 0,
        IN_TRANSIT: 0,
        MODIFIED: 0,
      },
    };
    const { data = defaultData } = useQuery(
      ['order-tracking-post-manager', selectedCountry, chainName, accountId],
      async () => {
        const { data = defaultData } = await api.post<IOrderTrackingPostManagerResponse>({
          url: `${API_ORDER_TRACKING_URL}/manager`,
          data: {
            ...defaultPostManagerData,
            accountId,
            page: 0,
            pageSize: 10,
          },
          config: { headers: { country: selectedCountry } },
        });

        return data;
      },
      { enabled: !!accountId.length, cacheTime: 0 }
    );

    return { data };
  };

  const getTrackings = (accountId: string, orderNumber: string) => {
    const defaultProduct: IProduct = {
      image: '',
      description: '',
      sku: '',
      ean: '',
      status: '',
      quantity: '',
      container: {
        itemSize: '',
        name: '',
        unitOfMeasurement: '',
      },
      package: {
        itemCount: '',
        name: '',
        pack: '',
        unitCount: '',
      },
    };

    const { data, isSuccess, isError } = useQuery<IOrderTrackingGetTrackingsResponse>(
      ['order-tracking-get-trackings', accountId, orderNumber, selectedCountry],
      async () => {
        const { data } = await api.get<IOrderTrackingGetTrackingsResponse>({
          url: `${API_ORDER_TRACKING_URL}/accounts/${accountId}/orders/${orderNumber}/trackings`,
          config: { headers: { country: selectedCountry } },
        });

        if (
          data?.content?.find(
            ({ description, status, quantity }) => !description || !status || !quantity
          )
        ) {
          throw new Error();
        }

        const content = data?.content.map((product) => ({
          ...product,
          container: {
            ...defaultProduct?.container,
            ...product?.container,
          },
          package: {
            ...defaultProduct?.package,
            ...product?.package,
          },
        }));

        return { content };
      },
      { enabled: !!accountId }
    );

    return { data: data?.content ?? [], isSuccess, isError };
  };

  return { post, postManager, getTrackings };
};

export type { IOrder, IOrderAccount, IProduct };
