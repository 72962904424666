import { Paragraph } from '@hexa-ui/components';
import { Store } from '@hexa-ui/icons';
import * as Styles from './PageSubtitle.styles';
import { IPageSubtitle } from './PageSubtitle.types';

export const PageSubtitle = ({ subtitle }: IPageSubtitle) => {
  return (
    <Styles.Container>
      <Store size="medium" />
      <Paragraph size="small" colortype="secondary">
        {subtitle}
      </Paragraph>
    </Styles.Container>
  );
};
