export const formatQuantity = (value: string | number, separator = '.') => {
  const number = Number(value);

  if (isNaN(number)) {
    return '';
  }

  return number
    .toLocaleString('en-US', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/,/g, separator);
};
