import { styled } from '@hexa-ui/theme';

export const PaginationWrapper = styled('div', {
  boxSizing: 'border-box',
  display: 'flex',
  position: 'relative',
  justifyContent: '$$justifyContent',
  width: '100%',
  padding: '24px 16px',
  '& #pagination-container': {
    alignItems: 'center',
  },
  '& .hexa-comobobox-dropdown-menu__card': {
    position: 'absolute',
    top: '55px',
    width: '185px!important',
    boxSizing: 'border-box',
    minHeight: 'auto',
    maxHeight: 'fit-content',
    padding: 0,
    overflow: 'hidden',
    '& #pagination-dropdown-menu': {
      justifyContent: 'center',
    },
  },
  '& > div': {
    '& > ul': { display: 'flex' },
    '& > nav': { display: 'none' },
    '@media (min-width: 870px)': {
      '& > ul': { display: 'none' },
      '& > nav': { display: 'flex' },
    },
  },
});
