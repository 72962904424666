import { IDeliveryTimeStatus } from '@api';
import { isDefinitelyReturned, isNotStarted } from '@utils';
import { useState } from 'react';
import * as Styles from './ProgressBarExpandable.styles';
import { ProgressBarExpandableTextStatus } from './sub-components';

interface IProgressBarExpandableProps {
  status: IDeliveryTimeStatus;
  updates: IDeliveryTimeStatus[];
  onOpenChange?: (isOpen: boolean) => void;
}

interface IRenderItem extends IDeliveryTimeStatus, IDeliveryTimeStatus {
  animation?: boolean;
  showLine?: boolean;
}

const renderItem = (data: IRenderItem) => {
  const { value, description, reason, timestamp, animation, showLine, color } = data;

  const _description =
    isDefinitelyReturned(value) && !!reason ? `${data.description} ${data.reason}` : description;

  return (
    <ProgressBarExpandableTextStatus
      color={color}
      text={_description}
      hour={timestamp}
      animation={animation}
      showLine={showLine}
      showHour={!isNotStarted(value)}
    />
  );
};

export const ProgressBarExpandable = ({
  status,
  updates,
  onOpenChange,
}: IProgressBarExpandableProps) => {
  const [isOpenExpanded, setIsOpenExpanded] = useState(false);
  const updatesWithSlice = updates.slice(1);
  const _updates = isOpenExpanded ? [status, ...updatesWithSlice] : updatesWithSlice;
  const headerText = isOpenExpanded
    ? ((<>&nbsp;</>) as unknown as string)
    : (renderItem({
        ...status,
        animation: true,
        showLine: false,
      }) as unknown as string);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpenExpanded(isOpen);
    if (onOpenChange) {
      onOpenChange(isOpen);
    }
  };

  return (
    <Styles.Expandable
      className={isOpenExpanded ? 'expanded' : ''}
      data-testid="expandable-status"
      onOpenChange={handleOpenChange}
      headerText={headerText}
      hasUpdates={updatesWithSlice.length > 0}
    >
      {_updates.map((update, index) =>
        renderItem({
          ...update,
          animation: index == 0,
          showLine: index < _updates.length - 1,
        })
      )}
    </Styles.Expandable>
  );
};
