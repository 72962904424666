import { useEffect, useState } from 'react';
import { UsePagination } from './usePagination.types';

export const usePagination: UsePagination = (rows, options) => {
  const { initialRowsPerPage = 5 } = options || {};
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  useEffect(() => {
    if (!rows) return;
    setTotalPages(Math.ceil(rows.length / rowsPerPage));
  }, [rowsPerPage, rows]);

  useEffect(() => {
    if (currentPage + 1 > totalPages) setCurrentPage(0);
  }, [currentPage, totalPages]);

  const onChangePagination = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setRowsPerPage(pageSize);
  };

  return {
    currentPage,
    rowsLength: rows?.length || 0,
    rowsPerPage,
    startIndex: currentPage * rowsPerPage,
    endIndex: currentPage * rowsPerPage + rowsPerPage,
    onChangePagination,
  };
};
