import { Paragraph as HexaParagraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const CardTextWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
});

export const Paragraph = styled(HexaParagraph, {
  fontSize: '$3',
  lineHeight: '$6',
  fontFamily: 'Work Sans !important',
  fontWeight: '400 !important',
  color: '#757575',
  variants: {
    truncate2Lines: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box!important',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        marginLeft: '9%',
        textAlign: 'center',
      },
    },
  },
});
